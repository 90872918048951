
import { defineComponent, PropType, ref } from 'vue'

import { IClientDetails } from '@server/api/models/fe/client'

import { formatClientListItem } from '@/utils/helpers'

export default defineComponent({
	name: 'AssociatedClientsCell',
	props: {
		clients: { type: Array as PropType<IClientDetails[]>, default: () => [] },
	},
	setup(props) {
		const minimized = ref(true)

		const handleClientName = (client: IClientDetails) => {
			return formatClientListItem(client).trim()
		}

		const handleSubText = () => {
			let subText = ''
			if (props.clients.length > 1) {
				if (minimized.value) {
					subText = `+ ${props.clients.length - 1} more`
				} else {
					subText = props.clients
						.map((client: IClientDetails) => handleClientName(client))
						.join(', ')
				}
			}
			return subText
		}

		const handleCellClick = () => {
			if (props.clients.length > 1) {
				minimized.value = !minimized.value
			}
		}

		return {
			minimized,
			handleSubText,
			handleClientName,
			handleCellClick,
		}
	},
})
